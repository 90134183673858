.setting-page.white-box{padding:2rem;margin-bottom: 2rem;}
.setting-option .setting-option-inner{display: flex;align-items: center;}
.setting-option .option-info{flex:1;padding-right: 1.5rem;}
.setting-option .option-field{max-width: 28rem;flex:0 0 28rem;position:relative}
.setting-option .blue-text{font-size: 1.8rem;font-weight: 500;color:#15397F;display: block;margin-bottom: 1.1rem;}
.setting-page-btn.form-btn-wrapper{margin-top: 0;}
.setting-page p.Mui-error{top:100%;margin-right: 0;margin-top:0;bottom:auto;right:0;text-align: right;}
.setting-page .form-group{max-width: 21rem;position: static;text-align: right;justify-content: flex-end;align-items: flex-end;margin-left: auto;display: flex;}

@media (max-width:767px){
    .setting-option .blue-text{font-size: 1.6rem;}
    .setting-option .setting-option-inner{flex-wrap:wrap}
    .setting-option .option-info{padding-right: 0;margin-bottom: 1.5rem;}
    .setting-option .option-field{max-width: 100%;flex:0 0 100%;}
    .setting-page .form-group{max-width: 100%;text-align: left;justify-content: flex-start;align-items: flex-start;}
    .setting-page p.Mui-error{text-align: left;}
}