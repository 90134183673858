.custom-dialog .MuiPaper-root { max-width: 64.8rem; margin: 2rem 2.4rem; box-shadow: none; background-color: transparent; } 
.custom-dialog .MuiPaper-root::-webkit-scrollbar { width: 0.4rem;}
.custom-dialog .MuiPaper-root::-webkit-scrollbar-track { background:#F5F5F5;border-radius: 0.2rem;}
.custom-dialog .MuiPaper-root::-webkit-scrollbar-thumb { background-color: #d3d3d4;border-radius: 0.2rem;}
.custom-dialog .dialog-content-wrapper { background-color: #fff; border-radius: 0.4rem; } 
.custom-dialog .MuiBackdrop-root { background-color: rgba(0,0,0,0.4); } 
.custom-dialog .MuiDialogActions-root { padding: 0; justify-content: center; } 
.custom-dialog .MuiDialogActions-root>:not(:first-of-type) { margin-left: 2.4rem; } 
.custom-dialog .MuiDialogActions-root button { min-width: 18.1rem; } 

.custom-dialog.confirm-box-wrapper .dialog-content-wrapper { padding: 5rem 3rem; text-align: center; } 
.custom-dialog.confirm-box-wrapper .dialog-content-wrapper .icon-block { display: block; margin-bottom: 1.9rem; } 
.custom-dialog.confirm-box-wrapper .dialog-content-wrapper .MuiDialogTitle-root { font-size: 2.6rem; font-weight: 500; color: #212121; padding: 0; margin-bottom: 1rem; } 
.custom-dialog.confirm-box-wrapper .dialog-content-wrapper p { color: #212121; } 
.custom-dialog.confirm-box-wrapper .dialog-content-wrapper .MuiDialogContent-root { padding: 0 6rem 3rem; } 

.custom-dialog.View-user-wrapper .MuiPaper-root{max-width:80.8rem;width:100%}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .title-wrapper{display:flex;align-items:center;justify-content:space-between;padding:2.4rem 3rem 2.4rem;border-bottom:1px solid #E7E8EC}
.custom-dialog.View-user-wrapper .title-wrapper h3{font-size:2.6rem;font-weight:500;color:#212121}
.custom-dialog.View-user-wrapper .title-wrapper .close-icon{cursor:pointer;width:3rem;height:3rem;text-align:center;padding:.5rem;background:0 0;outline:0;border:0;min-width:auto;box-sizing:border-box;box-shadow:none}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .MuiDialogContent-root{padding:3rem 3rem}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper{display:flex;flex-wrap:wrap;margin:0 -1.5rem -3rem}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper{flex:0 0 50%;max-width:50%;padding:0 1.5rem 3rem}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper .label{font-size:1.6rem;color:#979797;line-height:1.5;margin-bottom: 0.5rem;}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper .detail p{font-size:1.8rem!important;color:#212121;line-height:1.5;font-weight:500}
.custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper .detail .status-badge{display:inline-block;width:7rem;}
.custom-dialog.View-user-wrapper.email-preview-dialog .MuiPaper-root{max-width:76rem;width:100%}
.custom-dialog.email-preview-dialog .MuiDialogActions-root{padding:0 3rem 3rem 3rem;justify-content: flex-end;}

.custom-dialog.image-preview-dialogue .dialog-content-wrapper{border-radius: 0.8rem;background-color: transparent;}
.custom-dialog.image-preview-dialogue .image-wrapper{position: relative;padding-bottom: 56.25%;border-radius: 0.8rem;}
.custom-dialog.image-preview-dialogue .image-content-wrapper{border-radius: 0.8rem;background-color: #fff;}
.custom-dialog.image-preview-dialogue .image-wrapper img{position: absolute;height: 100%;width: 100%;object-fit:contain;left: 0;top: 0;border-radius: 0.8rem;}
.custom-dialog.image-preview-dialogue button{background-color: #fff;position: absolute;right: -2rem;top: -2rem;width: 4rem;height: 4rem;border-radius: 50%;border:none;cursor: pointer;box-shadow: 0 0 10px rgba(0,0,0,0.25)}
.custom-dialog.image-preview-dialogue button img{width: 1.7rem;}
.custom-dialog.image-preview-dialogue .dialog-content-wrapper .MuiDialogContent-root{padding: 0;}
.custom-dialog.image-preview-dialogue .MuiPaper-root{overflow-y: inherit;}
.custom-dialog.view-brokerage-wrapper .page-inner, .dialog-content-wrapper .page-inner { margin-top: 3rem;}

.custom-dialog.view-brokerage-wrapper .page-inner .filter-left-block, .dialog-content-wrapper .page-inner .filter-left-block {margin-bottom: 2rem;}

.custom-dialog.view-brokerage-wrapper .page-inner h4, .dialog-content-wrapper .dialog-content-wrapper {font-weight: 500;font-size: 2.4rem;color: #212121;}

.custom-dialog.view-brokerage-wrapper .inner-page-title-wrapper,
.dialog-content-wrapper .inner-page-title-wrapper {margin-bottom: 0rem;}

.view-brokerage-wrapper.View-user-wrapper .white-box.page-table-wrapper,
.dialog-content-wrapper .white-box.page-table-wrapper {padding:2rem 0 2rem; margin-bottom:0;}
.email-preview-dialog .table td, .email-preview-dialog .table a{font-family: 'Roboto',sans-serif  !important;}

.dialog-content-wrapper .user-detail-wrapper .view-member-print-button {display: flex; justify-content: flex-end;font-weight: 500; width: 45%;}
.dialog-content-wrapper .user-detail-wrapper .view-member-print-button .print-button {cursor:pointer}
.dialog-content-wrapper .user-detail-wrapper .view-member-print-button .print-button p{font-weight: 500; color: #ffffff; font-size: 18px; margin-left: 5px;}

@media(max-width:1199px) {
    .custom-dialog.confirm-box-wrapper .dialog-content-wrapper .MuiDialogTitle-root,
    .custom-dialog.View-user-wrapper .title-wrapper h3 { font-size: 2.4rem;}
}

@media(max-width:767px) {
    .custom-dialog.confirm-box-wrapper .dialog-content-wrapper .MuiDialogTitle-root { font-size: 2.2rem;line-height: 1.2;margin-bottom: 0.5rem;}
    .custom-dialog.confirm-box-wrapper .dialog-content-wrapper { padding: 3rem 2.4rem;}
    .custom-dialog.confirm-box-wrapper .dialog-content-wrapper .MuiDialogContent-root { padding: 0 0 2rem;}
    .custom-dialog .MuiDialogActions-root>:not(:first-of-type) { margin-left: 1rem;}
    .custom-dialog.confirm-box-wrapper .dialog-content-wrapper .icon-block { margin-bottom: 1rem;}
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper{margin:0 -1rem -2rem}
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper{padding:0 1rem 2rem}
    .custom-dialog.View-user-wrapper .title-wrapper h3{font-size: 2.2rem;}
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper .label{font-size: 1.4rem;}
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper .detail p {font-size: 1.6rem !important;}
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .MuiDialogContent-root{padding:1.5rem;}
    .custom-dialog.image-preview-dialogue .dialog-content-wrapper .MuiDialogContent-root{padding: 0;}
    .custom-dialog.email-preview-dialog .MuiDialogActions-root{padding:0 1.5rem 1.5rem 1.5rem;}
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .title-wrapper{padding:1.5rem}
    .custom-dialog.image-preview-dialogue button{right: -1.5rem;top: -1.5rem;width: 3rem;height: 3rem;}
    .custom-dialog.image-preview-dialogue button img{width: 1.5rem;}
}
@media(max-width:600px){
    .email-preview-dialog .table {
    width: 100% !important;
    }
    .email-preview-dialog table img {
    max-width: 100%;
    }
    .email-preview-dialog .outer-td-width {
    width: 15px !important;
    }
    .email-preview-dialog .outer-td-height {
    height: 10px !important;
    }
    .email-preview-dialog .fixed-height {
        height: 25px!important;
    }
    .email-preview-dialog .fixed-width{
        width: 25px!important;
    }
}

@media(max-width:479px) {
    .custom-dialog .MuiDialogActions-root button { min-width: 100%;}
    .custom-dialog .MuiDialogActions-root { flex-wrap: wrap;}
    .custom-dialog .MuiDialogActions-root>:not(:first-of-type) { margin:1rem 0 0;} 
    .custom-dialog.View-user-wrapper .dialog-content-wrapper .user-detail-wrapper .single-detail-wrapper{flex:0 0 100%;max-width:100%;}
    .email-preview-dialog .table-left-col{
        width: 60% !important;
        height: 40px !important;
       }
    .email-preview-dialog   .table-right-col{
        height: 40px !important;
       }
}