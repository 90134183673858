body,html{height:100%}
a,body,div,em,form,h1,h2,h3,h4,h5,h6,html,img,label,li,ol,p,section,span,ul{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
footer,header,section{display:block}
a,em,span,strong{display:inline-block}
ol,ul{list-style:none}
ul li{list-style:none}
html{font-size:100%;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%}
a{transition:all .5s;-webkit-transition:all .5s;-moz-transition:all .5s;-o-transition:all .5s;-ms-transition:all .5s;text-decoration:none;color:#2f64ff}
a:active,a:focus,a:hover{outline:0}
::-moz-selection{background:#15397F;text-shadow:none;color:#fff}
::selection{background:#15397F;text-shadow:none;color:#fff}
img{vertical-align:middle;display:inline-block;height:auto;max-width:100%;border:0;-ms-interpolation-mode:bicubic}
*,::after,::before{box-sizing:border-box}
input{caret-color:#15397F}
input::-webkit-input-placeholder{color:#b5b8c4!important;font-size:1.4rem!important;font-weight:400!important;opacity:1!important}
input:-ms-input-placeholder{color:#b5b8c4!important;font-size:1.4rem!important;font-weight:400;opacity:1!important}
input::placeholder{color:#b5b8c4!important;font-size:1.4rem!important;font-weight:400;opacity:1!important}
html{font-size:62.5%}
body{font-family:Roboto,sans-serif;font-size:1.6rem;background-color:#f5f5f5;font-weight:400;color:#9a9b9e}
.blue-btn{box-shadow:none!important;background-color:#15397f!important;color:#fff !important;font-size:1.8rem!important;font-weight:500!important;text-transform:capitalize!important;width:100%;height:5rem;line-height:5rem!important}
h1{font-size:2.8rem!important;font-weight:700!important;font-family:Roboto,sans-serif!important;color:#212121;margin-bottom:2rem!important}
h2{font-size:2.4rem!important;font-weight:500!important;font-family:Roboto,sans-serif!important;color:#212121;margin-bottom: 1.5rem !important;}
p{font-family:Roboto,sans-serif!important;font-size:1.6rem!important;font-weight:400;color:#9a9b9e}
.white-box{background-color:#fff;border-radius:4px;padding:2rem 2rem 2.4rem;margin-bottom:1.4rem}
.blue-link{color:#101941!important;font-size:1.4rem!important;font-weight:400!important;text-decoration:none!important}
.blue-link:hover{color:#15397f!important;text-decoration:underline!important}
.form-group label.Mui-focused{color:#15397f}
.form-group label.Mui-error{color:#15397f}
.form-group .MuiInputBase-root.Mui-error{border-color:#d32f2f}
.form-group fieldset{display:none}
.form-group .MuiInputBase-root{border:.1rem solid #b5b8c4;border-radius:.4rem;font-size:1.4rem;padding-right:0}
.error .MuiInputBase-root{border-color: #d32f2f;}
.form-group .MuiInputBase-root.MuiInputBase-multiline{padding:2rem;height: 10rem;}
.form-group .MuiInputBase-root.MuiInputBase-multiline textarea:first-child{height: 100% !important;padding: 0 !important;font-family:Roboto,sans-serif;line-height: 1.2;overflow-y: auto !important;padding-right: 1rem !important;margin-right: -1rem !important;}
.form-group .MuiInputBase-root:focus-within{border-color:#15397f}
p.Mui-error{margin: 0;font-size:1.2rem!important;margin-left:0;position:absolute;left:0;bottom:-2rem;color: #d32f2f;}

.form-group+.form-group{margin-top:2.2rem}
.PhoneInput input{border:.1rem solid #b5b8c4;border-radius:.4rem;font-size:1.4rem;padding:0 2rem 0 9.2rem;height:5rem;line-height:5rem;font-weight:500;color:#212121;font-family:Roboto,sans-serif;outline: none;}
.PhoneInput input:focus-visible{border-color: #15397f;}
.PhoneInputCountry{position: absolute;top: 50%;transform: translateY(-50%);z-index: 1;height: 100%;width: 6.3rem;justify-content: flex-end;}
.PhoneInputCountry::after{position: absolute;right: -0.8rem;height: 5rem;width: 0.1rem;background-color: #b5b8c4;content: '';}
.PhoneInputCountryIcon{width:2.8rem;height:2.8rem;position: relative;}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border{box-shadow: none;background-color: transparent;outline: none;}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border::before{opacity: 0;}
.PhoneInputCountryIcon svg{display: none;}
.PhoneInputCountryIcon::before{position: absolute;content: '';background-image: url('../assets/images/globe.svg');height: 2.8rem;width: 2.8rem;background-size: 2.8rem;background-position: center;}
.PhoneInputInternationalIconGlobe,.PhoneInputInternationalIconPhone{opacity: 1;color:#b5b8c4;}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe{color:#15397F;}
.PhoneInputCountrySelectArrow{position:relative;top:0.1rem;background-image: url(../assets/images/down-arrow.svg);border:none;background-repeat:no-repeat;transform:none;opacity: 1;width:1.2rem;height: 0.8rem;background-size: 1.2rem;background-position: center;margin-left:0.7rem;}
.MuiAutocomplete-root .MuiInputBase-root,
.form-group .MuiInputBase-input{padding:0 2rem !important;height:5rem;line-height:5rem;font-size:1.4rem;font-weight:500;color:#212121}
.MuiAutocomplete-root .MuiInputBase-root{height: inherit !important;min-height: 5rem;padding: 0rem 6rem 0rem 2rem !important;outline: none;}
.MuiOutlinedInput-root .MuiAutocomplete-input{padding: 0 !important;}
.form-group.password-wrapper .MuiInputBase-input{padding-right:0}
.form-group{position:relative;width:100%}
.form-group.password-wrapper .MuiButtonBase-root{width:4rem;margin-right:1rem}
.form-group.password-wrapper .MuiButtonBase-root svg{font-size:2.4rem;fill:#abaeb0}
/* .phone-input-wrapper p.Mui-error{left: 6.2rem;} */
.phone-input-wrapper .error input{border-color: #d32f2f;}
label,.form-group label,.phone-input-label{position:relative !important;top:0!important;transform:none !important;margin-bottom:1rem !important;color:#15397f !important;font-weight:500 !important;font-size:1.6rem !important;display:block !important;line-height:1.1 !important;max-width: fit-content;padding-right: 1rem;}
.checkbox-wrapper label span{font-size:1.4rem;color:#9a9b9e;line-height:1.7rem}
.checkbox-wrapper .MuiFormControlLabel-root{margin-left:-.7rem}
.checkbox-wrapper label span.MuiButtonBase-root{padding:.5rem}
.checkbox-wrapper .MuiSvgIcon-root{width:2rem;height:2rem}
.checkbox-wrapper .MuiCheckbox-root.Mui-checked{color:#15397f}
.checkbox-wrapper label span.MuiButtonBase-root:hover{background-color:rgba(0,0,0,.04)}
.status-badge{border-radius:0.4rem !important;min-width:7rem !important;text-align:center !important;display:block !important;height:2.2rem !important;line-height:2.2rem !important;color:#fff !important;font-size:1.4rem !important;text-transform:capitalize !important;padding:0 1rem !important;font-weight: 400 !important;}
.status-badge.active{background-color:#075e45}
.status-badge.inactive{background-color:#a1160a}
.form-group.search-field .MuiInputBase-root:focus-within {border-color: #15397F;}


.toast-notification .Toastify__toast{border-radius: 0.4rem;box-shadow:0 0 14px rgba(0,0,0,.08);}
.toast-notification > div {font-size: 1.8rem;color:#15397F;font-family:Roboto,sans-serif;}
.toast-notification .Toastify__toast-body .Toastify__toast-icon{width: 2.2rem;}
.toast-notification .Toastify__toast-body .Toastify__toast-icon svg{width:2.2rem;height: 2.2rem;}
.toast-notification .Toastify__toast-body svg path{fill:#15397f;}
.toast-notification .Toastify__close-button > svg{fill:#979797;width:1.8rem;height:2rem;}
.toast-notification .Toastify__close-button:hover > svg{fill:#979797;}
.toast-notification .Toastify__progress-bar{background-color:#15397F;}
.toast-notification .Toastify__close-button{padding:0.3rem;opacity: 1;color:#979797;position:relative;top:1rem;}
.toast-notification .Toastify__close-button:hover{color:#979797}
.toast-notification .Toastify__toast--error > div {color:#a1160a;}
.toast-notification .Toastify__toast--error .Toastify__toast-body svg path{fill:#a1160a;}
.toast-notification .Toastify__toast--success > div {color:#075e45;}
.toast-notification .Toastify__toast--success .Toastify__toast-body svg path{fill:#075e45;}
.toast-notification .Toastify__toast--warning > div {color:#f5c518;}
.toast-notification .Toastify__toast--warning .Toastify__toast-body svg path{fill:#f5c518;}
.toast-notification .Toastify__toast--info > div {color:#15397F;}
.toast-notification .Toastify__toast--info .Toastify__toast-body svg path{fill:#15397F;}
input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;-moz-appearance:none;appearance:none;margin:0}
input[type=number]{-moz-appearance:textfield;appearance:textfield}
.MuiTooltip-popper .MuiTooltip-tooltip{min-height: 24px;display: flex;align-items: center;font-size: 1.2rem;font-weight: 400;padding: 0 1rem;margin-top: 0.5rem !important;background-color: #7D7D7D;}
.MuiTooltip-popper .MuiTooltip-arrow{color: #7D7D7D !important;}
.dropdown-wrapper .form-group .MuiInputBase-root.Mui-focused,
.dropdown-wrapper .form-group .MuiInputBase-root:focus-within{border-color: #15397F;}
.page-table-wrapper{position:relative;min-height:300px;}
.page-loader{position: absolute;height: 100%;width: 100%;left: 0;top: 0;z-index: 2;background-color: rgba(233, 236, 243, 0.6);}
.page-loader em{display: block;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);}
button .btn-loader{position: absolute;left: 50%;top: calc(50% - 1px);transform: translate(-50%,-50%);}
.primary-btn,.blue-btn{position:relative;}
.page-wrapper .primary-btn.loader,button.loader{color:#15397F !important;}
.no-data-found{padding:4rem 0;text-align: center;font-size: 2.2rem;color: #000;}
.form-group .MuiFormLabel-asterisk{color: #d32f2f;position: relative;left: -0.2rem;top:-0.2rem}
.multi-select fieldset{border:0.1rem solid #b5b8c4}
.multi-select .MuiInputBase-root:hover fieldset{border-color:#b5b8c4;}
.multi-select .MuiFormControl-root:focus-visible{outline:none}
.multi-select .MuiFormControl-root:focus-visible fieldset{border-color: #15397F;}
.multi-select .Mui-focused .MuiOutlinedInput-notchedOutline{border: 0.1rem solid #15397F !important;}
.multi-select.MuiAutocomplete-root .MuiInputBase-root{padding:0 5.3rem 0 2rem !important;}
.MuiTabs-root .MuiButtonBase-root{font-size: 1.4rem;font-weight: 600;flex:none;text-transform:capitalize;}
.MuiTabs-root .MuiButtonBase-root:hover{color: #15397F;}
.MuiTabs-root .MuiButtonBase-root.Mui-selected{color: #15397F;}
.MuiTabs-root .MuiTabs-indicator{background-color: #15397F;}
.MuiTabs-flexContainer{border-bottom: 1px solid #E7E8EC}
@media (max-width:767px){
    h1{font-size:2.4rem!important}
    h2{font-size:2rem!important}
    .white-box{padding:2rem 2rem 2rem}
    .blue-btn{height:4.6rem;line-height:4.6rem!important;font-size:1.6rem!important}
    .form-group .MuiInputBase-input{padding:0 1.5rem;height:4.6rem;line-height:4.6rem}
    .form-group.password-wrapper .MuiButtonBase-root svg{font-size:2rem}
    .form-group.password-wrapper .MuiButtonBase-root{margin-right:.5rem}
    .PhoneInput input{padding: 0 1.5rem 0 8.5rem}
}
@media(max-width:574px){
    .MuiTabs-root .MuiButtonBase-root{font-size: 1.2rem;padding: 1rem;}
}