.dashboard-page-inner .dashboard-content-wrapper{display: flex;margin:0 -1.5rem 3rem;}
.dashboard-page-inner .dashboard-content-wrapper .dashboard-content{max-width:33.33%; flex: 0 0 33.33%;padding: 0 1.5rem;}
.dashboard-page-inner .dashboard-content-wrapper .dashboard-content .white-box{display: flex;justify-content: space-between;border-radius: 1.6rem;padding:3.6rem 3rem 2.2rem;height: 100%;}
.dashboard-page-inner .dashboard-content-wrapper .dashboard-content .dashboard-info{padding-right: 1.4rem;}
.dashboard-page-inner .dashboard-content-wrapper .dashboard-info h2{margin-bottom: 0 !important;font-size: 4rem !important;font-weight: 700 !important;color: #000 !important;}
.dashboard-page-inner .dashboard-content-wrapper .image-wrapper{max-width:9rem;flex:0 0 9rem;border-radius: 1.6rem;background-color: #EBF2FF;display: flex;align-items: center;justify-content: center;height: 9rem;}
.dashboard-page-inner .dashboard-content-wrapper .dashboard-info p{color: #999A9E;font-size: 2rem !important;font-weight: 400;}
.dashboard-page-inner .dashboard-chart-wrapper{display: flex;margin:0 -1.5rem;}
.dashboard-page-inner .dashboard-chart-wrapper > div {max-width: 100%;flex:0 0 100%;padding: 0 1.5rem;}
.dashboard-page-inner .dashboard-chart-wrapper .two-col{max-width: 50%;flex:0 0 50%;padding: 0 1.5rem;}
.dashboard-page-inner .dashboard-chart-wrapper .white-box{border-radius: 1.6rem;padding:2.4rem 3rem 2.4rem;height: 100%;margin-bottom: 0;display: flex;flex-direction: column;justify-content: space-between;}
.dashboard-page-inner .dashboard-chart-wrapper h3{font-size: 2rem !important;font-weight: 700 !important;color: #212121 !important;padding-bottom: 1.2rem;margin-bottom: 3rem;border-bottom: 0.1rem solid #DDDDDD;}
.dashboard-page-inner .dashboard-chart-wrapper .right-block-inner canvas{height: 31rem !important;width: 31rem !important;margin: 0 auto;}

/* @media(min-width:768px){
    .dashboard-page-inner .dashboard-chart-wrapper .left-block-inner canvas{height: 100% !important;width: 100% !important;}
} */
@media(max-width:1280px){
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-info p{font-size: 1.8rem !important;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-info h2{font-size: 3.2rem !important;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-content .white-box{padding: 3rem 2rem 1.8rem;}
}
@media(max-width:1199px){
    .dashboard-page-inner .dashboard-chart-wrapper{flex-wrap: wrap;margin:0 0 -3rem;}
    .dashboard-page-inner .dashboard-chart-wrapper .two-col{max-width: 100%;flex:0 0 100%;margin-bottom: 3rem;padding: 0;}
    .dashboard-page-inner .dashboard-content-wrapper .image-wrapper{max-width: 6rem;flex: 0 0 6rem;height: 6rem;padding: 1.2rem;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-info p{font-size: 1.6rem !important;}
}
@media(max-width:991px){
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-info h2{font-size: 2.8rem !important;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-info p{font-size: 1.4rem !important;}
    .dashboard-page-inner .dashboard-content-wrapper{margin: 0 -1rem 2rem;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-content{padding:0 1rem}
    .dashboard-page-inner .dashboard-chart-wrapper .two-col{margin-bottom: 2rem;}
    .dashboard-page-inner .dashboard-chart-wrapper{margin:0 0 -2rem;}
}
@media(max-width:767px){
    .dashboard-page-inner .dashboard-content-wrapper{flex-wrap: wrap;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-content{max-width: 100%;flex:0 0 100%;}
    .dashboard-page-inner .dashboard-content-wrapper .dashboard-content + .dashboard-content{margin-top: 2rem;}
    .dashboard-page-inner .dashboard-chart-wrapper .white-box{padding:2.4rem 2rem}
}
@media(max-width:374px){
    .dashboard-page-inner .dashboard-chart-wrapper .right-block-inner canvas{width: 100%!important;height: 100%!important;}    
}