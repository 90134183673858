.login-page .login-wrapper{display:flex;align-items:center;justify-content:center;height:100%;min-height:100vh}
.login-page .login-wrapper .login-block{max-width:57rem;padding:5rem 2.4rem;margin:0 auto;width:100%}
.login-page .login-wrapper .logo-wrapper{text-align:center;margin-bottom:8rem}
.login-page .login-wrapper .logo-wrapper .logo{max-width:23.8rem;margin:0 auto}
.login-page .login-wrapper h1{text-align:center;margin-bottom:2.9rem!important}
.login-page.verify-otp-page .login-block h1{margin-bottom:1rem!important}
.otp-wrapper label{position:relative;top:0!important;transform:none;margin-bottom:1.1rem;color:#15397f;font-weight:500;font-size:1.6rem;display:block;line-height:1}
.login-wrapper .checkbox-wrapper{display:flex;justify-content:space-between;margin:0 0 1.4rem;align-items:center}
.verify-otp-page .login-block>p{text-align:center;margin-bottom:3rem}
.verify-otp-page .link-wrapper{margin:1.6rem 0;text-align:center}
.verify-otp-page .otp-wrapper>div{justify-content:space-between}
.verify-otp-page .otp-wrapper>div input{width:auto!important;height:5rem;border:.1rem solid #b5b8c4;border-radius:.4rem;font-weight:500;font-size:1.6rem;font-family:Roboto;color:#212121;flex:0 0 15%;max-width:15%}
.verify-otp-page .otp-wrapper>div input+input{margin-left:.8rem}
.verify-otp-page .otp-wrapper>div input:focus-visible{border-color:#15397f;outline:0}
.login-page.reset-password-page .btn-wrapper{margin-top:2rem}
.verify-otp-page button.blue-link{box-shadow:none;background-color:transparent;color:#101941!important;font-size:1.4rem!important;font-weight:400!important;text-transform:none;padding:0}
.verify-otp-page button.blue-link:hover{color:#15397f!important;text-decoration:underline!important}

@media (max-width:1440px){
  .login-page .login-wrapper .login-block{max-width:56rem;padding:3rem 2rem;margin:0 auto;width:100%}
}
@media (max-width:991px){
  .login-page .login-wrapper .logo-wrapper{margin-bottom:5rem}
}
@media(max-width:767px){
  .login-page .login-wrapper .logo-wrapper{margin-bottom:4rem}
}
@media (max-width:574px){
  .verify-otp-page .otp-wrapper>div input{width:auto!important;height:4rem;max-width:14%;flex:0 0 14%}
  .login-page .login-wrapper .login-block{max-width:100%}
}