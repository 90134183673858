.table-wrapper {
	width: 100%
}

.table-wrapper .MuiDataGrid-root {
	border: 0;
	height: 100% !important
}

.table-wrapper .MuiDataGrid-withBorderColor {
	border-color: transparent !important
}

.table-wrapper .MuiDataGrid-cell.MuiDataGrid-withBorderColor,
.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
	border-color: transparent !important;
	padding: 1rem 1rem !important;
	/* flex: auto !important */
}
.table-wrapper .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
    padding: 1.4rem 1.5rem 1.6rem !important;
}


.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor {
	padding: 1rem 1.5rem 1rem !important;
    border-color: transparent !important;
	position: relative;
}
.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:last-child{
	padding: 1rem 1.5rem 1rem 1.5rem!important;
}
.table-wrapper.no-checkbox .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:last-child{
	padding: 1rem 2rem 1rem 1.5rem!important;
}
.table-wrapper .MuiDataGrid-row.MuiDataGrid-row--lastVisible .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
	position: relative
}
.table-wrapper .MuiDataGrid-row.MuiDataGrid-row--lastVisible .MuiDataGrid-cell.MuiDataGrid-withBorderColor:after {
    position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	height: 0.1rem;
	width: 100%;
	background-color: #e7e8ec !important;
}
.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:after {
    bottom: .1rem;
}
.table-wrapper .MuiDataGrid-columnHeaders:first-child .MuiDataGrid-columnHeaderTitleContainerContent{
	overflow:initial;
}
.table-wrapper .MuiDataGrid-row.MuiDataGrid-row--lastVisible .MuiDataGrid-cell.MuiDataGrid-withBorderColor:first-child:after{
    width: calc(100% - 1rem);
	left: 1rem
}
.table-wrapper .MuiDataGrid-row.MuiDataGrid-row--lastVisible .MuiDataGrid-cell.MuiDataGrid-withBorderColor:last-child:after {
    width: calc(100% - 1rem)
}
.table-wrapper.no-checkbox .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:last-child:after,
.table-wrapper.no-checkbox .MuiDataGrid-row.MuiDataGrid-row--lastVisible .MuiDataGrid-cell.MuiDataGrid-withBorderColor:last-child:after,  
.table-wrapper.no-checkbox .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:first-child:after,
.table-wrapper.no-checkbox .MuiDataGrid-row.MuiDataGrid-row--lastVisible .MuiDataGrid-cell.MuiDataGrid-withBorderColor:first-child:after{
	left: 0;
	width: 100%;
}


.table-wrapper .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle {
	font-weight: 500;
	color: #212121;
    /* line-height: 3rem; */
}

.table-wrapper .MuiDataGrid-columnHeader {
	font-size: 1.4rem;
	color: #9a9b9e;
	line-height: 1.17
}

.table-wrapper .MuiDataGrid-cellContent {
	font-size: 1.4rem;
	color: #212121;
	line-height: 1.17
}

.table-wrapper .action-heading-wrapper {
	display: flex;
	align-items: center
}
.table-wrapper .action-heading-wrapper p{
	color: #212121;
	font-weight: 500;
}
.table-wrapper .action-heading-wrapper .action-btn-wrapper .user-profile-menu-link {
	color: #15397f;
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0;
	background-color: transparent
}

.table-wrapper .action-heading-wrapper .action-btn-wrapper .user-profile-menu-link:focus,
.table-wrapper .action-heading-wrapper .action-btn-wrapper .user-profile-menu-link:hover {
	background-color: rgba(21, 57, 127, .05)
}

.table-wrapper .action-heading-wrapper .action-btn-wrapper .user-profile-menu-link img {
	width: .35rem;
	height: auto
}

.table-wrapper .MuiDataGrid-cell:focus,
.table-wrapper .MuiDataGrid-cell:focus-within,
.table-wrapper .MuiDataGrid-columnHeader:focus,
.table-wrapper .MuiDataGrid-columnHeader:focus-within,
.table-wrapper .action-heading-wrapper .action-btn-wrapper .user-profile-menu-link:focus {
	outline: 0 !important
}

.table-wrapper .action-heading-wrapper .action-btn-wrapper .user-profile-menu-link .MuiTouchRipple-root {
	display: none
}

.table-wrapper span.user-fname {
	font-size: 1.4rem;
	color: #212121;
	line-height: 3rem;
    display: block;
    min-height: 3rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.table-wrapper .action-column-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between
}

.table-wrapper .action-column-wrapper .action-column-inner {
	flex: 0 0 3rem;
	max-width: 3rem
}

.table-wrapper .action-column-wrapper .action-column-inner .MuiButton-root {
	min-width: auto;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	padding: 0.5rem;
	background-color: transparent;
	box-shadow: none;
}
.table-wrapper .action-column-wrapper .action-column-inner .MuiButton-root.Mui-disabled {
  opacity: 0.6;
}

.table-wrapper .action-column-wrapper .action-column-inner .MuiButton-root img {
	width: 2rem;
	height: auto
}

.table-wrapper .action-column-wrapper .action-column-inner .MuiButton-root.edit-icon img {
	width: 1.6rem
}
.table-wrapper .MuiDataGrid-columnHeaderTitleContainer{height: 100%;}
.table-wrapper .MuiDataGrid-cellCheckbox .MuiCheckbox-root,
.table-wrapper .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput {
	padding: 0;
	color: #9a9b9e;
	height: 3rem;
	width: 3rem
}

.table-wrapper .MuiDataGrid-cellCheckbox .MuiCheckbox-root:hover,
.table-wrapper .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput:hover {
	background-color: rgba(21, 57, 127, .04)
}

.table-wrapper .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-checked,
.table-wrapper .MuiDataGrid-cellCheckbox .MuiCheckbox-root.MuiCheckbox-indeterminate,
.table-wrapper .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput.Mui-checked,
.table-wrapper .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput.MuiCheckbox-indeterminate {
	color: #15397f !important
}

.table-wrapper .MuiDataGrid-cellCheckbox .MuiCheckbox-root .MuiSvgIcon-root {
	width: 2.6rem;
	height: 2.6rem;
}

.table-wrapper .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput .MuiSvgIcon-root {
	width: 2.6rem;
	height: 2.6rem
}

.table-wrapper .MuiDataGrid-cellCheckbox .MuiCheckbox-root .MuiSvgIcon-root .MuiTouchRipple-root,
.table-wrapper .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput .MuiTouchRipple-root {
	width: 3rem;
	height: 3rem
}

.table-wrapper .MuiDataGrid-virtualScrollerRenderZone {
	width: 100% !important
}

.table-wrapper .MuiDataGrid-row.Mui-hovered,
.table-wrapper .MuiDataGrid-row.Mui-selected,
.table-wrapper .MuiDataGrid-row:hover {
	background-color: transparent !important
}

.table-wrapper .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
	display: none
}

.table-wrapper .MuiDataGrid-columnHeader.action-col .MuiDataGrid-columnHeaderTitleContainer {
	justify-content: flex-end
}
.table-wrapper.no-checkbox .MuiDataGrid-columnHeader.action-col .MuiDataGrid-columnHeaderTitleContainer{
	justify-content: flex-start;
}
.table-wrapper .pdf-wrapper a em{
	margin-right: 1rem;
}
.table-wrapper .pdf-wrapper a{
	font-size: 1.4rem;
	color: #15397F;
	font-weight: 500;
	text-decoration: underline;
}
.table-wrapper .pdf-wrapper a:hover{
	text-decoration: none;
}
.table-wrapper .MuiDataGrid-columnHeaders{
	background-color: #F5F5F5;
	border-radius: 0.4rem;
}

/* table popupover menu in pagination start  */
.MuiPopover-paper{box-shadow:0 0 14px rgba(0,0,0,.08)!important;border-radius:4px}
.MuiPopover-paper .MuiMenu-list .MuiMenuItem-root.MuiTablePagination-menuItem{font-size:1.3rem;padding:0.5rem 1rem;color:#212121;background-color:transparent!important;border-radius:.4rem;margin: 0 1rem;min-height: inherit;}
.MuiPopover-paper .MuiMenu-list .MuiMenuItem-root.MuiTablePagination-menuItem:hover{background-color:rgba(21,57,127,.1)!important;color:#15397f}
/* table popupover menu in pagination end  */

.table-action-menu .MuiPopover-paper{box-shadow:0 0 14px rgba(0,0,0,.08)!important;border-radius:4px}
.table-action-menu .MuiPopover-paper .MuiMenu-list{padding:1rem}
.table-action-menu .MuiPopover-paper .MuiMenu-list .MuiMenuItem-root{font-size:1.4rem;min-width:8rem;padding:.5rem 1rem;border-radius:.4rem}
.table-action-menu .MuiPopover-paper .MuiMenu-list .MuiMenuItem-root:hover{background-color:rgba(21,57,127,.1)!important;color:#15397f}



/* table footer */
.table-wrapper .MuiDataGrid-footerContainer{min-height:auto;padding-top: 2rem;}
.table-wrapper .MuiDataGrid-footerContainer .MuiTablePagination-toolbar{min-height:auto;padding:0 0 0 1rem}
.table-wrapper .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount{font-size:1.3rem;color:#5f6580;margin:0;padding:0;opacity:0}
.table-wrapper .MuiTablePagination-selectLabel{font-size:1.3rem!important;color:#5f6580;line-height:1.17}
.table-wrapper .MuiTablePagination-select.MuiInputBase-input{font-size:1.3rem;color:#5f6580;min-height:auto;line-height:1.17;padding-right:2rem!important;background-color:transparent!important;padding-bottom:0!important;padding-top:0}
.table-wrapper .MuiTablePagination-selectIcon{width:2rem;height:2rem;top:50%;transform:translateY(-50%);margin-left:0}
.table-wrapper .MuiInputBase-root{margin-right:2rem;margin-left:0}
.table-wrapper .MuiTablePagination-displayedRows{font-size:1.3rem!important;color:#5f6580;line-height:1.17}
.table-wrapper .MuiTablePagination-actions .MuiIconButton-root{width:2.8rem;height:2.8rem}
.table-wrapper .MuiTablePagination-actions .MuiIconButton-root+.MuiIconButton-root{margin-left:1.2rem}
.table-wrapper .MuiTablePagination-actions .MuiIconButton-root:hover{background-color:rgba(21,57,127,.05)!important}
.table-wrapper .MuiTablePagination-actions .MuiIconButton-root .MuiSvgIcon-root{color:#15397f;font-size:2.8rem}
.table-wrapper .MuiTablePagination-actions .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root{color:#bac4da}
.notification-action{display: flex;}
.notification-action .status-badge{cursor: pointer;box-shadow: none !important;}
.notification-action .status-badge.active:hover{background-color: rgba(7,94,69,0.8);}
.notification-action .status-badge.inactive:hover{background-color:rgba(161,22,10,0.8)}
.notification-action button+button{margin-left: 1rem;}