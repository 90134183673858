.form-row-wrapper { display: flex;flex-wrap: wrap;margin: 0 -1.4rem -2.8rem;}
.has-required label::after{position: absolute;right: -1.3rem;content: "*";width: 2rem;color: #d32f2f;}
.otp-wrapper.has-required label::after{right: -2.3rem;}
.form-row-wrapper .form-col { max-width: 50%;flex: 0 0 50%;margin-bottom: 2.8rem;padding: 0 1.4rem;}
.form-row-wrapper .form-addresses { max-width: 16.66%;flex: 0 0 16.66%;margin-bottom: 2.8rem;padding: 0 1.4rem;}
.form-row-wrapper .form-address-field { max-width: 50%;flex: 0 0 50%;margin-bottom: 2.8rem;padding: 0 1.4rem;}
.form-row-wrapper .form-col.full-width{ max-width: 100%;flex: 0 0 100%;}
.form-row-wrapper .form-col.small-width{ max-width: 33.33%;flex: 0 0 33.33%;}
.form-row-wrapper .form-col.extra-small-width{ max-width: 20%;flex: 0 0 20%;}
.form-row-wrapper .form-col.medium-small-width{ max-width: 25%;flex: 0 0 25%;}
.form-row-wrapper .form-col.fullwidth-column{ max-width: 100%;flex:0 0 100%;}
.form-btn-wrapper { margin-top: 2.5rem;text-align: right;}
.shadow-box { box-shadow:  0px 4px 29px 0px rgba(0, 0, 0, 0.01);}
.form-row-wrapper .disclaimer-checkbox { margin-bottom: 2rem;display: flex;align-items: center;}
.form-row-wrapper .disclaimer-checkbox label{ margin-bottom: 0!important;}
.form-row-wrapper .form-col .disclaimer-note{ top: 275px;left: 40px; color: #15397F; font-weight: 500; font-size: 1.5rem;margin-bottom: 2rem;}
.form-row-wrapper .estimateOutOfPocket{position: relative; width: 100%; flex: 0 0 50%; margin-bottom: 2.8rem;}
.form-row-wrapper .estimateOutOfPocket .outofpocket-radio{display: flex;position: absolute; bottom: 90px; left: 265px; z-index: 10;}
.form-row-wrapper .estimateOutOfPocket .outofpocket-radio label{display: flex!important; margin-bottom: 0!important; }
/* editor */
.text-editor{margin: 0 0 74px !important;}
.textarea-editor{margin: 0 !important;}
.form-row-wrapper .editor-has-required{max-width: fit-content!important;}
.form-row-wrapper .editor-has-required::after{position: absolute;right: -1.1rem;content: "*";width: 2rem;color: #d32f2f;}
.ql-toolbar.ql-snow{border-top-left-radius: 5px;border-top-right-radius: 5px;}
.ql-container.ql-snow{border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {z-index: 2;}
.ql-container.ql-snow .ql-tooltip {left: 0px !important;z-index: 2;}
.text-editor ul li{color:#212121;font-size:1.5rem; }
.text-editor ol li{color:#212121;font-size:1.5rem; }
.textarea-editor ul li{color:#212121;font-size:1.5rem; }
.textarea-editor ol li{color:#212121;font-size:1.5rem; }
.inner-html ol, .inner-html ol li, .inner-text-html ol, .inner-text-html ol li {font-size:1.6rem; list-style: auto; margin-left:20px; color: #212121;}
.inner-html ul, .inner-html ul li, .inner-text-html ul, .inner-text-html ul li {font-size:1.6rem; list-style: disc; margin-left:20px; color: #212121;}
.inner-html p, .text-editor p, .textarea-editor p, .inner-text-html p {color: #212121;}
/* select */
.form-group.select-group .MuiInputBase-root{ margin-top: 0; }
.form-group.select-group .MuiInputBase-root::after,
.form-group.select-group .MuiInputBase-root::before { display: none;}
.form-group.select-group .MuiInputBase-root .MuiSelect-select { background-color: transparent !important;padding-right: 6rem !important;}
.form-group.select-group .MuiInputBase-root.Mui-focused { border-color: #15397F;}
.MuiAutocomplete-endAdornment svg,
.form-group.select-group .MuiSelect-select ~ svg{display: none; }
.MuiAutocomplete-root .MuiInputBase-root.Mui-focused::after,
.form-group.select-group .MuiSelect-select[aria-expanded=true]:after { transform: rotate(180deg);}
.MuiAutocomplete-root .MuiInputBase-root::after,
.form-group.select-group .MuiSelect-select:after { position: absolute;content: '';top: 0;height: 100%;width: 7.2rem;right: 0;background-image: url(../assets/images/down-arrow.svg);background-repeat: no-repeat;background-position: center;background-size: 1.2rem;transition: all 0.5s;}
.form-group.select-group .MuiSelect-select .placeholder-text { color:#b5b8c4;font-size:1.4rem;font-weight:400;}
.MuiAutocomplete-root .MuiInputBase-root::after,
.form-group.select-group .MuiSelect-select::after{width: 4rem;}
.MuiOutlinedInput-root .MuiAutocomplete-endAdornment{right: 0 !important;top: 50%;transform: translateY(-50%);}
.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {right: 3rem;top: 0;height: 100%;}
.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator svg{display: block;width: 1.4em;height: 1.4em;font-size: 1.15rem;}
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-clearIndicator{visibility: visible;}
.custom-select-dropdown { box-shadow: none !important;border-radius:0.4rem!important;margin-top: 0.5rem;box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08) !important;}
.custom-select-dropdown ul li .MuiTouchRipple-root { display: none;}
.MuiAutocomplete-noOptions,
.MuiAutocomplete-listbox .MuiAutocomplete-option,
.custom-select-dropdown ul li {padding: 1.3rem 2rem !important; font-size: 1.6rem;color: #212121;background-color: transparent;margin: 0 1rem;border-radius: 0.4rem;}
.custom-select-dropdown.multiselect-dropdown ul li {padding-left: 0.7rem !important;} 
.MuiAutocomplete-listbox .MuiAutocomplete-option:hover,
.custom-select-dropdown ul li:hover{background-color:rgba(21,57,127,0.1)!important;color:#15397f;}
.MuiMenu-list .Mui-selected{background-color:rgba(21,57,127,0.1)!important;color:#15397f;}
.custom-select-dropdown ul li.placeholder-item { display: none;}
.blue-title,
.file-upload-wrapper label{margin-bottom: 1.1rem;color: #15397f;font-weight: 500;font-size: 1.6rem;display: block;line-height: 1;}
.file-upload-wrapper input{width:100%;opacity: 0;color: transparent;z-index: 2;position: absolute;height: 100%;cursor: pointer;}
.file-upload-wrapper {position: relative;}
.file-upload-wrapper .file-upload-outer{position: relative;height: 17rem;border-radius: 0.4rem;background-position: 0 0, 0 0, 100% 0, 0 100%;background-size: 0.13rem 100%, 100% 0.16rem, 0.13rem 100% , 100% 0.13rem;background-repeat: no-repeat;background-image: repeating-linear-gradient(0deg, #B5B8C4, #B5B8C4 1rem, transparent 1rem, transparent 2rem), repeating-linear-gradient(90deg, #B5B8C4, #B5B8C4 1rem, transparent 1rem, transparent 2rem), repeating-linear-gradient(180deg, #B5B8C4, #B5B8C4 1rem, transparent 1rem, transparent 2rem), repeating-linear-gradient(270deg, #B5B8C4, #B5B8C4 1rem, transparent 1rem, transparent 2rem);}
.file-upload-wrapper .file-upload-outer .file-upload-icon{display: flex;flex-direction: column;height: 100%;align-items: center;justify-content: center;}
.file-upload-wrapper .file-upload-outer .file-upload-icon p {font-weight: 500;font-size: 1.6rem;color: #B5B8C4;margin-top: 2.4rem;}
.file-upload-wrapper .file-upload-outer .file-upload-icon p span{color: #15397F;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper{margin-top: 2rem;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper{display: flex;border-radius: 0.4rem;padding: 0.8rem 1.2rem;background-color: #F5F5F5;align-items: center;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper .action-wrapper{max-width: 5.6rem;flex:0 0 5.6rem}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper .action-wrapper button{min-width: 2rem;border-radius: 50%;padding:0.4rem;background: none;box-shadow: none;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper .action-wrapper a+a{margin-left: 0.5rem;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper .action-wrapper a{cursor: pointer;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper+.image-wrapper{margin-top: 1.5rem;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper+.image-wrapper em img{min-width: 2rem;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper .action-wrapper img{width: 2rem;height: auto;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper .image-name{overflow: hidden;text-overflow: ellipsis;white-space: nowrap;flex:1;padding:0 1rem;color: #000;font-size: 1.4rem;}
.file-upload-wrapper .file-upload-inner .image-outer-wrapper .image-wrapper em{flex:0 0 2rem;max-width: 2rem;}
.two-btns{margin-top: 3rem;}
.form-btn-wrapper .border-btn{margin-right: 2rem;}
.form-btn-wrapper .float-left{float: left;}
.suffix .MuiInputAdornment-root p{font-size: 1.4rem !important;padding-right: 2rem;color:#B5B8C4}
.prefix .MuiInputAdornment-root p{font-size: 1.4rem !important;color:#B5B8C4;padding-left: 0.7rem;}
.form-group.prefix .MuiInputBase-input{padding-left: 0;}
.MuiAutocomplete-listbox .MuiAutocomplete-option .MuiCheckbox-root .MuiSvgIcon-root{width: 2.6rem;height: 2.6rem;}
.MuiAutocomplete-root .MuiChip-label{font-size: 1.4rem;}
.MuiChip-root{background-color: #f5f5f5;}
.MuiChip-root .MuiChip-deleteIcon{color:#b5b8c4 !important;}
.PrivateSwitchBase-input:checked + svg{color: #15397f !important;}
.MuiAutocomplete-listbox .MuiAutocomplete-option .MuiCheckbox-root{padding: 0;color: #9a9b9e;height: 3rem;width: 3rem}

@media(max-width:1199px){
    .form-row-wrapper .form-col.small-width {max-width: 50%;flex: 0 0 50%;}
    .form-row-wrapper .form-col.extra-small-width{ max-width: 50%;flex: 0 0 50%;}
    .form-row-wrapper .form-col.medium-small-width{ max-width: 50%;flex: 0 0 50%;}
    .form-row-wrapper .estimateOutOfPocket .outofpocket-radio{flex-direction: column;}
    .form-row-wrapper .form-addresses { max-width: 50%;flex: 0 0 50%;margin-bottom: 2.8rem;padding: 0 1.4rem;}
}
@media(max-width:767px) {
    .form-row-wrapper { margin: 0;}
    .form-row-wrapper .form-col.small-width,.form-row-wrapper .extra-small-width,
    .form-row-wrapper .form-col {   max-width: 100%;flex: 0 0 100%;}
    .form-row-wrapper .form-col:last-child { margin-bottom: 0;}
    .form-group.select-group .MuiInputBase-root .MuiSelect-select { padding-right: 5rem;}
    .form-group.select-group .MuiSelect-select:after { width: 5rem;}
    .custom-select-dropdown ul li { padding: 1rem 1.5rem;}    
    .suffix .MuiInputAdornment-root p{padding-right: 1.5rem;}
    .prefix .MuiInputAdornment-root p{padding-left: 0.1rem;}
    .file-upload-wrapper .file-upload-outer .file-upload-icon em img{height: 3.6rem;}
    .file-upload-wrapper .file-upload-outer .file-upload-icon p{margin-top: 1rem;}
    .form-row-wrapper .estimateOutOfPocket .outofpocket-radio{bottom: 60px;}
    .form-row-wrapper .estimateOutOfPocket .outofpocket-radio{flex-direction: row; }
    .form-row-wrapper .estimateOutOfPocket{flex: 0 0 100%;}
}
@media(max-width:574px){
    .file-upload-wrapper{padding-bottom: 2rem;}
    .create-clone-email .form-btn-wrapper .border-btn{margin-right: 1rem;}
    .form-row-wrapper .form-col.extra-small-width, .form-row-wrapper .form-col.medium-small-width{ max-width: 100%;flex: 0 0 100%;}
    .form-row-wrapper .estimateOutOfPocket .outofpocket-radio{margin:1rem 1rem; position: static;}
}
@media(max-width:426px){
    .file-upload-wrapper .file-upload-outer {height: 15rem;}        
    .create-clone-email .form-btn-wrapper .border-btn{margin-bottom: 1.5rem;margin-right: 0;}
    .create-clone-email .form-btn-wrapper{display: flex;flex-direction: column;}
}
@media(max-width:374px){
    .form-btn-wrapper{display: flex;flex-direction: column;}
    .form-btn-wrapper button{flex:0 0 100%;max-width: 100%;}
    .form-btn-wrapper .border-btn{margin-bottom: 1.5rem;margin-right: 0;}
}