.page-wrapper .primary-btn,.custom-dialog .primary-btn,.page-wrapper .primary-btn.Mui-disabled,.custom-dialog .primary-btn.Mui-disabled {font-size: 1.6rem; font-weight: 500 !important;color: #fff !important;background-color: #15397F;border:1px solid #15397F; height: 4.9rem; line-height: 4.9rem !important; padding: 0 3rem; border-radius: 0.4rem;display: inline-flex; align-items: center;justify-content: center;text-decoration: none !important; text-transform: capitalize;box-shadow: none;}
.page-wrapper .primary-btn.Mui-disabled{opacity: 0.6;}
.page-wrapper .primary-btn:hover,.custom-dialog .primary-btn:hover {text-decoration: none;background-color: #102d65;box-shadow: none;border:1px solid #102d65;}
.page-wrapper .primary-btn.border-btn,.custom-dialog .primary-btn.border-btn { border:1px solid #15397F;color: #15397F !important;background-color: transparent;}
.page-wrapper .primary-btn.border-btn:hover,.custom-dialog .primary-btn.border-btn:hover { color: #fff !important;background-color: #15397F;}
.page-wrapper { padding:7.8rem 0 0 27.6rem; transition: padding 0.5s;}
.sidebar-close .page-wrapper {padding-left: 0;}
.page-wrapper .page-inner { padding: 3rem 2.4rem;}
.page-wrapper .page-inner .page-content-block{position: relative;}
.inner-page-title-wrapper {display: flex; justify-content: space-between;margin-bottom: 2rem;align-items: center;}
.inner-page-title-wrapper h1 {margin-bottom: 0 !important;}
.page-wrapper .breadcrumbs-wrapper {margin-bottom: 1rem;}
.page-wrapper .breadcrumbs-wrapper ol li {font-size: 1.6rem;}
.page-wrapper .breadcrumbs-wrapper ol li.MuiBreadcrumbs-separator {margin-left: 0.5rem;margin-right: 0.5rem;}
.page-wrapper .breadcrumbs-wrapper ol li a {font-size: 1.6rem; color: #9A9B9E;text-decoration: none;}
.page-wrapper .breadcrumbs-wrapper ol li a:hover {text-decoration: none;}
.page-wrapper .breadcrumbs-wrapper ol li:last-child span {color: #15397F;}
.inner-page-title-wrapper .title-right-block .right-block-inner {display: flex;}
.form-group.search-field {position: relative; width: 32.1rem;}
.form-group.search-field input {padding-right: 5rem; font-size: 1.6rem; color: #212121;}
.form-group.search-field label {font-size: 0;margin: 0;}
.form-group.search-field .MuiInputBase-root {border-color: #E9ECF3; background-color: #fff;padding-right: 0;}
.form-group.search-field svg {width: 2.2rem;height: 2.2rem;fill: #B5B8C4;position: absolute;right: 2rem;}
.form-group.search-field .MuiInputAdornment-root {margin-left: 0;}
.form-group.search-field input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
.form-group.search-field input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
.form-group.search-field input[type="search"]::-webkit-search-decoration,
.form-group.search-field input[type="search"]::-webkit-search-cancel-button,
.form-group.search-field input[type="search"]::-webkit-search-results-button,
.form-group.search-field input[type="search"]::-webkit-search-results-decoration { display: none; }
.form-group.search-field input::-webkit-input-placeholder { font-size: 1.6rem !important; color: #B5B8C4; opacity: 1 !important;}
.form-group.search-field input::-moz-placeholder { font-size: 1.6rem !important; color: #B5B8C4; opacity: 1 !important;}
.form-group.search-field input:-ms-input-placeholder { font-size: 1.6rem !important; color: #B5B8C4; opacity: 1 !important;}
.form-group.search-field input:-moz-placeholder { font-size: 1.6rem !important; color: #B5B8C4; opacity: 1 !important;}
.inner-page-title-wrapper .title-right-block .right-block-inner .primary-btn {margin-left: 1.5rem;}
.profile-page .white-box:not(:last-child){margin-bottom: 3.4rem;}
.secondary-title-wrapper {display: flex; justify-content: space-between;margin-bottom: 1.5rem;}
.secondary-title-wrapper h2 {margin-bottom: 0 !important;}
.profile-page .primary-btn,.settings-page .primary-btn {min-width: 9.8rem;}
.dropdown-wrapper{margin-left: 1.5rem;}
.page-wrapper .page-inner .filter-wrapper{display: flex;justify-content: space-between;margin-bottom: 2rem;}
.page-wrapper .page-inner .filter-wrapper .filter-right-block{display: flex;}
.dropdown-wrapper .form-group label{display: none;}
.dropdown-wrapper .form-group.select-group .MuiInputBase-root{border-color: #E9ECF3;background-color: #fff;padding-right: 0;width: 16.4rem;}
.has-large-dropdown.dropdown-wrapper .form-group.select-group .MuiInputBase-root{width: 25rem;}
.dropdown-wrapper .form-group.select-group .MuiSelect-select:after{width: 4rem;}
.dropdown-wrapper .form-group.select-group .MuiInputBase-root .MuiSelect-select{padding-right: 4rem;font-size: 1.6rem;}
.dropdown-wrapper .form-group.select-group .MuiSelect-select .placeholder-text{font-size: 1.6rem;}

/* Media Css */
@media(max-width:1199px) {
    .page-wrapper .primary-btn,.custom-dialog .primary-btn,.page-wrapper .primary-btn.Mui-disabled {padding: 0 1.5rem;font-size: 1.4rem !important;}    
    .form-group.search-field{width: 30rem;}
    .page-wrapper .page-inner .filter-wrapper.memberlist-filter-wrapper{flex-direction: column;}
    .page-wrapper .page-inner .filter-wrapper.memberlist-filter-wrapper .filter-left-block{margin-bottom: 2rem}
    .page-wrapper .page-inner .filter-wrapper.memberlist-filter-wrapper .filter-right-block .has-large-dropdown.dropdown-wrapper{margin-left: 0;}
    .page-wrapper .page-inner .filter-wrapper.memberlist-filter-wrapper .dropdown-wrapper .form-group.select-group .MuiInputBase-root,
    .page-wrapper .page-inner .filter-wrapper.memberlist-filter-wrapper .form-group.search-field{width: 100%;}
    .page-wrapper .page-inner .filter-wrapper .filter-right-block .dropdown-wrapper{width: 50%;}
}
@media(max-width:1023px) {
    .page-wrapper { padding-left: 0;}
}
@media(max-width:767px) {    
    .page-wrapper .breadcrumbs-wrapper ol li,.page-wrapper .breadcrumbs-wrapper ol li a {font-size: 1.4rem;}    
    .inner-page-title-wrapper .title-right-block .right-block-inner {justify-content: space-between;}
    .page-wrapper .primary-btn,.custom-dialog .primary-btn,.page-wrapper .border-btn,.custom-dialog .border-btn,.page-wrapper .primary-btn.Mui-disabled  {height: 4.5rem; line-height: 4.5rem !important;}
    .page-wrapper .page-inner .filter-wrapper .filter-right-block,
    .page-wrapper .page-inner .filter-wrapper{flex-direction: column;}
    .page-wrapper .page-inner .filter-wrapper .filter-right-block .dropdown-wrapper,
    .dropdown-wrapper .form-group.select-group .MuiInputBase-root,
    .form-group.search-field{width: 100%;}
    .dropdown-wrapper{margin-left: 0;margin-top: 1.5rem;}
    .form-group.search-field svg {right:1rem}
    .form-group.search-field input {padding-right: 4rem;}
    .page-wrapper .page-inner .filter-wrapper.memberlist-filter-wrapper .filter-left-block{margin-bottom: 0rem}
}
@media(max-width:374px){
    .inner-page-title-wrapper {flex-direction: column;align-items: flex-start;}
    .inner-page-title-wrapper .title-right-block .right-block-inner .primary-btn {margin-left: 0;margin-top: 1.5rem;}
}