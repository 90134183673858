.sidebar-close .site-header { left: 0 !important; } 
.site-header { background-color: #fff !important; box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05) !important; padding: 1.8rem 0; left: 30rem !important; transition: left 0.5s !important; width: auto !important; } 
.site-header .MuiToolbar-root { min-height: inherit; padding: 0 2.4rem; } 
.site-header .site-header-wrapper { display: flex; align-items: center; justify-content: space-between; width: 100%; } 
.site-header .site-header-wrapper .hamburger-menu img { width: 1.8rem; } 
.site-header .site-header-wrapper .hamburger-menu { margin: -1rem; padding: 1rem; cursor: pointer; } 
.user-profile-menu ul { width: 19.7rem; padding: 2rem 2rem 0.3rem 2rem; } 
.user-profile-menu ul li { padding: 1.3rem 0; font-size: 1.4rem; color: #212121; background-color: transparent !important; margin-bottom: 0.4rem; } 
.user-profile-menu ul li .MuiListItemIcon-root { min-width: 3.3rem; } 
.user-profile-menu ul li:hover { color: #15397F; } 
.user-profile-menu ul li:not(:last-child) { border-bottom: 1px solid #E7E8EC; } 
.user-profile-menu .MuiPaper-root { box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08); border-radius: 0.4rem; margin-top: 1.2rem; } 
.user-profile-menu .user-profile-wrapper { cursor: default; background-color: transparent !important; border-bottom: 0 !important; padding-top: 0 !important; padding-bottom: 0.7rem; margin-bottom: 0; } 
.user-profile-menu li .MuiTouchRipple-root { display: none; } 
.user-profile-menu .user-profile-wrapper .user-profile { display: flex; width: 100%; align-items: center; } 
.user-profile-menu .user-profile-wrapper .user-profile .user-img-btn {margin-right: 0.8rem; }
.page-wrapper .user-img-btn ,.user-profile-menu .user-profile-wrapper .user-profile .user-img-btn { height: 4.2rem; width: 4.2rem; background-color: #E9ECF3 !important; font-size: 1.6rem; font-weight: 500; color: #15397F; display: flex; align-items: center; justify-content: center; position: relative; font-family: 'Roboto', sans-serif; border-radius: 50%; min-width: 4.2rem; } 
.page-wrapper .user-img-btn img,.user-profile-menu .user-profile-wrapper .user-profile .user-img-btn img { height: 4.2rem; width: 4.2rem; position: absolute; left: 0; top: 0; object-fit: cover; border-radius: 50%; } 
.user-profile-menu .user-profile-wrapper .user-profile .user-detail p { color: #212121; font-size: 1.6rem; font-weight: 500; line-height: 1; margin-bottom: 0.5rem; } 
.user-profile-menu .user-profile-wrapper .user-profile .user-detail span { color: #5F6580; font-size: 1.2rem; display: block; line-height: 1; } 
.page-wrapper .user-img-btn {cursor: pointer;}
@media(max-width:1023px) { 
 .site-header { left: 0 !important; } 
}