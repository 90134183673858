.sidebar-close aside { left: -28rem; } 
aside { position: fixed; left: 0; top: 0; height: 100%; width: 27rem; background-color: #fff; padding: 2rem 0.8rem 2.7rem; transition: left 0.5s; z-index: 1102;} 
aside .sidebar-logo img { width: 14.8rem; } 
aside .sidebar-logo { margin-bottom: 2.9rem;padding: 0 1.2rem;}
aside .sidebar-links-wrapper { min-height: calc(100% - 10.8rem);max-height: calc(100% - 10.8rem);overflow-y: auto;padding-right: 1rem;margin-right: -1rem;}
aside .sidebar-links-wrapper ul { padding: 0;} 
aside .sidebar-links-wrapper ul ul { margin: 0;}
aside .sidebar-links-wrapper ul ul li a { font-size: 1.4rem;min-height: 4rem;}
aside .sidebar-links-wrapper ul ul li a .MuiTypography-root { font-size: 1.4rem;}
aside .sidebar-links-wrapper ul li { padding: 0;cursor: pointer;padding: 0 1.2rem;border-radius: 4px;transition: all 0.5s;margin-bottom: 1rem;position: relative;} 
aside .sidebar-links-wrapper ul li:hover a::after{ background-color: rgba(21, 57, 127, 0.05)} 
aside .sidebar-links-wrapper ul li a::after { position: absolute;content:'';left: 0;top: 0;height: 100%;width: 100%;transition: all 0.5s;background-color: transparent;border-radius: 4px;z-index: -1;}
aside .sidebar-links-wrapper ul li.submenu-open .arrow-icon::before{ transform:rotate(90deg);}
aside .sidebar-links-wrapper ul li .active + .arrow-icon::before,aside .sidebar-links-wrapper ul li.submenu-open .arrow-icon::before{ background-image: url(../assets/images/right-arrow-white.svg);}
aside .sidebar-links-wrapper ul li .active::after{ background-color: rgba(21, 57, 127,1) !important}
aside .sidebar-links-wrapper ul li.submenu-open a::after{ background-color: rgba(21, 57, 127,1);}
aside .sidebar-links-wrapper ul li.submenu-open a {color: #fff;}
aside .sidebar-links-wrapper ul li .active { color: #fff;}
aside .sidebar-links-wrapper ul li a .MuiTouchRipple-root {display: none;}
aside .sidebar-links-wrapper ul li .arrow-icon { min-width: 4rem;height: 4.4rem;position: relative;} 
aside .sidebar-links-wrapper ul li .arrow-icon::before { position: absolute;content: '';left: 0;top: 1.3rem;height: 2rem;width: 2rem; background-image: url(../assets/images/right-arrow-gray.svg);background-repeat: no-repeat;background-size: 0.8rem;background-position: center;transition: all 0.5s;left: 2rem;} 
aside .sidebar-links-wrapper ul li a .MuiButtonBase-root { padding: 0; background-color: transparent !important; } 
aside .sidebar-links-wrapper ul li a em { line-height: 0; position: relative; margin-right:1.5rem; min-width: 2.4rem; } 
aside .sidebar-links-wrapper ul li a em img { transition: all 0.5s;}
aside .sidebar-links-wrapper ul li a em img.white-icon { position: absolute;left: 0;top: 0;opacity: 0;}
aside .sidebar-links-wrapper ul li.submenu-open a em img.white-icon { opacity: 1;}
aside .sidebar-links-wrapper ul li.submenu-open a em img.normal-icon { opacity: 0;}
aside .sidebar-links-wrapper ul li .active em img.white-icon { opacity: 1;}
aside .sidebar-links-wrapper ul li .activeem img.normal-icon { opacity: 0;}
aside .sidebar-links-wrapper ul li a { font-size: 1.6rem; color: #404767; min-height: 4.4rem; display: flex;align-items: center;flex: 1;} 
aside .sidebar-links-wrapper ul li a .MuiTypography-root { font-size: 1.6rem; font-family: 'Roboto', sans-serif;letter-spacing: normal;} 
aside .sidebar-links-wrapper ul li a .MuiListItemText-root { margin: 0; } 
aside .footer-copy-right{ color: #B5B8C4;font-size: 1.6rem;margin-top: 2rem;text-align: center;}
aside .footer-copy-right .blue-link { font-size: 1.6rem !important;}
textarea::-webkit-scrollbar,
aside .sidebar-links-wrapper::-webkit-scrollbar { width: 0.4rem;}
textarea::-webkit-scrollbar-track,
aside .sidebar-links-wrapper::-webkit-scrollbar-track { background:#F5F5F5;border-radius: 0.2rem;}
textarea::-webkit-scrollbar-thumb,
aside .sidebar-links-wrapper::-webkit-scrollbar-thumb { background-color: #d3d3d4;border-radius: 0.2rem;}
aside .sidebar-links-wrapper .MuiCollapse-wrapperInner ul li .active::after {background-color:rgba(21, 57, 127,0.1) !important;}
aside .sidebar-links-wrapper .MuiCollapse-wrapperInner ul li a::before{position: absolute;content: '';top: 50%;transform: translateY(-50%);left:3rem;height: 0.8rem;width: 0.8rem;background-color: #404767;border-radius: 50%;transition: all 0.3s;}
aside .sidebar-links-wrapper .MuiCollapse-wrapperInner ul li a em{display: none;}
aside .sidebar-links-wrapper .MuiCollapse-wrapperInner ul li a{padding-left: 4rem;}
aside .sidebar-links-wrapper .MuiCollapse-wrapperInner ul li a.active::before{background-color: #15397f;}
aside .sidebar-links-wrapper .MuiCollapse-wrapperInner ul li .active{color: #15397f;}

aside .sidebar-logo .close-sidebar img { width: 1.2rem;}
@media(min-width:1024px) {
    aside .sidebar-logo .close-sidebar { display: none;}
}
@media(max-width:1023px) {
    aside { left: -28rem !important;}
    .sidebar-open aside { left: 0 !important;}
    aside .sidebar-logo .close-sidebar { display: inline-flex;height: 3rem;width: 3rem;border-radius: 50%;background-color: #15397f;align-items: center;justify-content: center;}    
    aside .sidebar-logo { display: flex;align-items: center;justify-content: space-between;}
}
